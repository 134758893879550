import {
  Tooltip,
  Box,
  Button,
  Container,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
  Spinner,
  SlideFade,
  Td,
  ScaleFade,
  CircularProgress,
  Skeleton,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyService from '../../services/company-service';
import ExposureService from '../../services/exposure-service';
import style from '../../assets/style.json';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { TaskListIcon } from '../../assets/TakListIcon';
import { ReportIcon } from '../../assets/ReportIcon';
import { TrashIcon } from '../../assets/TrashIcon';
import { AIIcon } from '../../assets/AIIcon';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';
import { getCache, setCache } from '../../services/cache';

const Companies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);

  const { isLoading, setIsLoading } = useContext(LanguageContext);

  const loadExposure = async () => {
    try {
      const exposure = await ExposureService.companies();
      return exposure;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const loadData = async () => {
    try {
      setIsLoading(true);

      const res = await CompanyService.list();
      setCompanies(res);
      return res;
    } catch (error) {
      console.error('Error fetching data:', error);
      navigate('/home');
    } finally {
      setIsLoading(false);
    }
  };

  const load = async () => {
    const cacheKey = 'companiesData';
    const ttl = 5 * 60 * 1000; // 5 minutes

    const cachedData = getCache(cacheKey);

    if (cachedData) {
      setCompanies(cachedData);
      setIsLoading(false);
    } else {
      try {
        const [res, exposure] = await Promise.all([loadData(), loadExposure()]);

        const companiesData = res.map((company: any) => ({
          ...company,
          exposure: exposure.find((item: any) => item.companyId === company.id)
            ?.exposure,
        }));

        setCompanies(companiesData);
        setCache(cacheKey, companiesData, ttl);
      } catch (error) {
        console.error('Error fetching data:', error);
        navigate('/home');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    //@ts-ignore
    <Container
      maxW={'full'}
      minH="100vh"
      py={20}
      position="absolute"
      top={'90px'}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {t('companies.companies')}
        </Text>
      </SlideFade>

      {isLoading ? (
        <>
          {' '}
          <Box
            w="90vw"
            maxW={style['pc-view']}
            boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
            bg="#fff"
            p={'20px'}
            rounded="10px"
          >
            <Skeleton
              height="40px"
              width="200px"
              borderRadius="3xl"
              textAlign={'center'}
              marginBottom="30px"
            />
            <Box overflowX={'auto'}>
              <Table>
                <Thead borderBottom={'1.5px solid #DFDFDF'}>
                  <Tr>
                    <Th
                      fontSize={'16px'}
                      fontWeight={700}
                      lineHeight={'29px'}
                      paddingBottom="12px"
                      textAlign="start"
                      position="sticky"
                      right={'0'}
                      zIndex={5}
                      bg="white"
                    >
                      <Skeleton height="20px" width="100px" />
                    </Th>
                    <Th
                      fontSize={'16px'}
                      fontWeight={700}
                      lineHeight={'29px'}
                      paddingBottom="12px"
                      textAlign="start"
                    >
                      <Skeleton height="20px" width="100px" />
                    </Th>
                    <Th
                      fontSize={'16px'}
                      fontWeight={700}
                      lineHeight={'29px'}
                      paddingBottom="12px"
                      textAlign="start"
                    >
                      <Skeleton height="20px" width="100px" />
                    </Th>
                    <Th
                      fontSize={'16px'}
                      fontWeight={700}
                      lineHeight={'29px'}
                      paddingBottom="12px"
                      textAlign="start"
                    ></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {[...Array(3)].map((_, i) => (
                    <Tr key={i} rounded={'10px'} border={'none'}>
                      <Td
                        cursor="pointer"
                        roundedRight="10px"
                        bg={i % 2 === 0 ? 'white' : '#FFF'}
                        border={'none'}
                        textAlign="start"
                        position="sticky"
                        right={'0'}
                        zIndex={5}
                      >
                        <Skeleton height="20px" width="100px" />
                      </Td>
                      <Td
                        bg={i % 2 === 0 ? 'white' : '#FFF'}
                        border={'none'}
                        textAlign="start"
                      >
                        <Skeleton height="20px" width="150px" />
                      </Td>
                      <Td
                        cursor="pointer"
                        maxW="200px"
                        bg={i % 2 === 0 ? 'white' : '#FFF'}
                        border={'none'}
                        textAlign="start"
                      >
                        <Skeleton
                          height="40px"
                          width="40px"
                          borderRadius="50%"
                        />
                      </Td>
                      <Td
                        bg={i % 2 === 0 ? 'white' : '#FFF'}
                        border={'none'}
                        textAlign="start"
                        roundedLeft={'10px'}
                      >
                        <HStack spacing={3} justifyContent="center">
                          <Skeleton height="40px" width="40px" />
                          <Skeleton height="40px" width="40px" />
                          <Skeleton height="40px" width="40px" />
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <SlideFade in>
            <Box
              w="90vw"
              maxW={style['pc-view']}
              boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
              bg="#fff"
              p={'20px'}
              rounded="10px"
            >
              <Button
                onClick={() => navigate('/admin/company')}
                minW={'188px'}
                textAlign="center"
                fontSize="18px"
                fontWeight={700}
                rounded={'100px'}
                borderWidth={'1.5px'}
                borderColor={style['primary-color']}
                bg={'#fff'}
                color={style['primary-color']}
                marginBottom="30px"
              >
                <Box gap={'17px'} display={'flex'} alignItems={'center'}>
                  <Text>{t('companies.addCompany')}</Text>
                  <CirclePlusIcon />
                </Box>
              </Button>
              <Box overflowX={'auto'}>
                <Table>
                  <Thead borderBottom={'1.5px solid #DFDFDF'}>
                    <Tr>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                        position="sticky"
                        right={'0'}
                        zIndex={5}
                        bg="white"
                      >
                        {t('companies.name')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('companies.field')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('companies.performance')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      ></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {companies.map((company: any, i: number) => (
                      <Tr
                        key={'company-' + company.id + i}
                        rounded={'10px'}
                        border={'none'}
                      >
                        <Td
                          onClick={() =>
                            navigate(`/admin/company/${company.id}`)
                          }
                          cursor="pointer"
                          roundedRight="10px"
                          bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                          border={'none'}
                          textAlign="start"
                          position="sticky"
                          right={'0'}
                          zIndex={5}
                        >
                          {company.name}
                        </Td>
                        <Td
                          bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                          border={'none'}
                          textAlign="start"
                        >
                          {company.field}
                        </Td>
                        <Td
                          onClick={() =>
                            navigate(`/admin/exposure/${company.id}`)
                          }
                          cursor="pointer"
                          maxW="200px"
                          bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                          border={'none'}
                          textAlign="start"
                        >
                          {!isNaN(+company.exposure) ? (
                            <ScaleFade in initialScale={0}>
                              <HStack spacing={1}>
                                <Text textAlign={'end'} width="50px">
                                  {(company.exposure * 100).toFixed(0)}%
                                </Text>
                                <CircularProgress
                                  value={company.exposure * 100}
                                  size="40px"
                                  thickness="10px"
                                  color={
                                    company.exposure * 100 < 25
                                      ? 'red.500'
                                      : company.exposure * 100 < 50
                                      ? 'yellow.300'
                                      : company.exposure * 100 < 85
                                      ? '#F6A860'
                                      : '#3FB2D2'
                                  }
                                />
                              </HStack>
                            </ScaleFade>
                          ) : (
                            <Skeleton
                              height="40px"
                              width="40px"
                              borderRadius="50%"
                            />
                          )}
                        </Td>
                        <Td
                          bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                          border={'none'}
                          textAlign="start"
                          roundedLeft={'10px'}
                        >
                          <HStack spacing={3} justifyContent="center">
                            <Button
                              color={'#3FB2D2'}
                              bg="transparent"
                              onClick={() =>
                                navigate({
                                  pathname: `/admin/onboarding/${company.id}`,
                                })
                              }
                            >
                              O
                            </Button>
                            <Button
                              color={'#3FB2D2'}
                              bg="transparent"
                              onClick={() =>
                                navigate({
                                  pathname: `/admin/risks-files/${company.id}`,
                                })
                              }
                            >
                              <AIIcon />
                            </Button>
                            <Button
                              color={'#3FB2D2'}
                              bg="transparent"
                              onClick={() =>
                                navigate({
                                  pathname: '/admin/tasklists',
                                  search: `?company_id=${company.id}`,
                                })
                              }
                            >
                              <TaskListIcon />
                            </Button>

                            <Button
                              color={'#3FB2D2'}
                              bg="transparent"
                              onClick={() =>
                                navigate({
                                  pathname: `/admin/reports/${company.id}`,
                                })
                              }
                            >
                              <ReportIcon />
                            </Button>

                            <Button
                              fontWeight="bold"
                              color={
                                company.id !== '123' ? '#3FB2D2' : 'gray.800'
                              }
                              fontSize={17}
                              bg="transparent"
                              rounded="xl"
                              opacity={company.id !== '123' ? 1 : 0.6}
                              cursor={
                                company.id !== '123' ? 'pointer' : 'not-allowed'
                              }
                              onClick={async () => {
                                if (company.id !== '123') {
                                  await CompanyService.delete(
                                    company.name,
                                    company.id,
                                  );
                                  load();
                                }
                              }}
                            >
                              <TrashIcon />
                            </Button>
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </SlideFade>
        </>
      )}
    </Container>
  );
};

export default Companies;
