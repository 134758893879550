import { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  position,
  Text,
  useMediaQuery,
  Select,
} from '@chakra-ui/react';
import { ArrowDownIcon } from '../assets/ArrowDownIcon';
import bg from '../assets/bgLogin.png';
import UserService from '../services/user-service';
import style from '../assets/style.json';
import { Hamburger } from '../assets/Hamburger';
import { Logo } from './Footer/styleComponent/Logo';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../locales/LocaleContext';

export function Topbar({
  currRole,
  setCurrRole,
  userRoles,
  userInfo,
  setUserRoles,
  setUserInfo,
  setIsSidebarOpen,
  setIsNewsletterOnly,
}: {
  currRole: any;
  setCurrRole: any;
  userRoles: any;
  userInfo: any;
  setUserRoles: any;
  setUserInfo: any;
  setIsSidebarOpen: any;
  setIsNewsletterOnly: any;
}) {
  const { t, i18n } = useTranslation();
  const { locale, setLocale } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDark, setIsDark] = useState(false);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [isTablet] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    setIsDark(
      pathname.includes('view-newslatter') ||
        pathname.includes('about') ||
        pathname.includes('p-term-of-use') ||
        pathname.includes('contact') ||
        pathname.includes('p-privacy-policy'),
    );
  }, [pathname]);

  const load = async () => {
    const user = await UserService.info();
    const isNewsletterOnly = user?.isNewsletterOnly;
    setIsNewsletterOnly(isNewsletterOnly);
    setUserInfo(user);

    if (
      !user &&
      !pathname.startsWith('/privacy-policy') &&
      !pathname.startsWith('/term-of-use')
    ) {
      if (pathname !== '/') navigate('/', { replace: true });
      return;
    }
    if (pathname === '/') {
      const rid = searchParams.get('rid');
      if (rid !== null) await UserService.updateRid(rid!);

      navigate('/home', { replace: true });
    }

    // if (!user.tos && !pathname.startsWith('/term-of-use')) {
    //   navigate('/term-of-use', { replace: true });
    // }

    if (
      user.isUnlisted &&
      user.company.onboardingQuestionnaireId &&
      !pathname.startsWith('/onboarding')
    ) {
      navigate('/onboarding', { replace: true });
    }

    if (!userRoles || !currRole) setUserRoles(user.roles);

    if (!currRole) {
      setCurrRole(user.roles?.length ? user.roles[0] : null);
    }
  };

  useEffect(() => {
    load();
  }, [pathname]);

  const toggleLanguage = (newLanguage: string) => {
    localStorage.setItem('locale', newLanguage);
    setLocale(newLanguage);
  };

  return pathname !== '/' &&
    !pathname.startsWith('/privacy-policy') &&
    !pathname.startsWith('/onboarding') &&
    !pathname.startsWith('/term-of-use') ? (
    <Box>
      {/* @ts-ignore */}
      <Container
        top={0}
        width={'100%'}
        minHeight={'100vh'}
        backgroundColor={style.background}
        position="absolute"
        zIndex={-2}
        padding={0}
        margin={0}
        maxW={'100%'}
      ></Container>
      <Container
        backgroundColor={isDark ? '' : '#20273d'}
        backgroundPosition="center"
        backgroundImage={bg}
        width={'100%'}
        height={'400px'}
        maxW={'100%'}
        position="absolute"
      />

      <Box position="absolute" width={'100%'} height="16px" bg="#3FB2D2"></Box>
      <Box
        position="absolute"
        width={'100%'}
        maxWidth={style['pc-view+20']}
        top={'60px'}
        left={'50%'}
        transform="translateX(-50%)"
        display={'flex'}
        justifyContent="space-between"
        paddingInline={isPc ? '0px' : '10px'}
        zIndex={11}
      >
        <Box
          display={'flex'}
          color="red"
          alignItems="center"
          gap={'8px'}
          flexFlow={isTablet ? 'column' : ''}
          alignContent={isTablet ? 'flex-start' : ''}
        >
          <Button
            onClick={(ev) => {
              //@ts-ignore
              ev.stopPropagation();
              setIsSidebarOpen(true);
            }}
            color="white"
            bg="transparent"
          >
            <Hamburger color={isDark ? '#20273d' : 'white'} />
          </Button>
          {isTablet && ( // Conditionally render based on isPc (1300px min width)
            // <Select
            //   width="60px"
            //   marginLeft="10px"
            //   value={i18n.language}
            //   onChange={(e) => toggleLanguage(e.target.value)}
            //   color={isDark ? '#20273d' : 'white'}
            //   background={'transparent'}
            //   borderColor={isDark ? '#20273d' : 'white'}
            //   className={locale === 'en' ? 'en' : 'he'}
            //   minW={isPc ? '113px' : '70px'}
            // >
            //   <option value="en" style={{ color: '#000' }} className="En">
            //     EN
            //   </option>
            //   <option value="he" style={{ color: '#000' }} className="He">
            //     HE
            //   </option>
            // </Select>
            <Menu matchWidth={true} size="sm" closeOnSelect={true}>
              <MenuButton>
                <Box
                  bg="#F5F8FA"
                  p={'10px'}
                  rounded="10px"
                  width={'100px'}
                  display="flex"
                  alignItems="center"
                  justifyContent={'space-between'}
                >
                  {i18n.language.toUpperCase()}
                  <Box px={2}>
                    <ArrowDownIcon />
                  </Box>
                </Box>
              </MenuButton>
              <MenuList
                width={'100px !important'}
                minWidth={'100px !important'}
              >
                <MenuItem
                  onClick={() => {
                    toggleLanguage('he');
                  }}
                  _hover={{
                    bg: '#F5F8FA',
                  }}
                  display="flex"
                  justifyContent={'space-between'}
                >
                  HE
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleLanguage('EN');
                  }}
                  _hover={{
                    bg: '#F5F8FA',
                  }}
                  display="flex"
                  justifyContent={'space-between'}
                >
                  EN
                </MenuItem>
              </MenuList>
            </Menu>
          )}

          {isPc && (
            <>
              <Image
                src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                w="50px"
                rounded="full"
                h="auto"
              />
              <Box
                paddingInlineStart={'9px'}
                textAlign={'start'}
                color={isDark ? '#20273d' : 'white'}
              >
                <Text fontWeight="bold" fontSize={20}>
                  {userInfo?.name}
                </Text>
                <Text>{currRole?.name || ''}</Text>
              </Box>
            </>
          )}
        </Box>
        <Box
          display={'flex'}
          alignItems={isTablet ? 'flex-start' : 'center'}
          gap="16px"
        >
          {!isTablet && ( // Conditionally render based on isTablet (767px max width)
            <>
              <Menu matchWidth={true} size="sm" closeOnSelect={true}>
                <MenuButton>
                  <Box
                    bg="#F5F8FA"
                    p={'10px'}
                    rounded="10px"
                    width={'100px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={'space-between'}
                  >
                    {i18n.language.toUpperCase()}
                    <Box px={2}>
                      <ArrowDownIcon />
                    </Box>
                  </Box>
                </MenuButton>
                <MenuList
                  width={'100px !important'}
                  minWidth={'100px !important'}
                >
                  <MenuItem
                    onClick={() => {
                      toggleLanguage('he');
                    }}
                    _hover={{
                      bg: '#F5F8FA',
                    }}
                    display="flex"
                    justifyContent={'space-between'}
                  >
                    HE
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      toggleLanguage('EN');
                    }}
                    _hover={{
                      bg: '#F5F8FA',
                    }}
                    display="flex"
                    justifyContent={'space-between'}
                  >
                    EN
                  </MenuItem>
                </MenuList>
              </Menu>
              {/* <Select
                width="60px"
                marginLeft="10px"
                value={i18n.language}
                onChange={(e) => toggleLanguage(e.target.value)}
                color={isDark ? '#20273d' : 'white'}
                background={'transparent'}
                borderColor={isDark ? '#20273d' : 'white'}
                className={locale === 'en' ? 'en' : 'he'}
                minW={isPc ? '113px' : '70px'}
              >
                <option value="en" style={{ color: '#000' }} className="En">
                  EN
                </option>
                <option value="he" style={{ color: '#000' }} className="He">
                  HE
                </option>
              </Select> */}
            </>
          )}
          <Button
            color={isDark ? '#20273d' : 'white'}
            minW={isPc ? '113px' : '70px'}
            textAlign="center"
            background={'transparent'}
            fontSize="18px"
            fontWeight={700}
            rounded={'100px'}
            display="flex"
            alignItems={'center'}
            gap="8px"
            border={
              isPc ? `1.5px solid ${isDark ? '#20273d' : 'white'}` : 'none'
            }
            onClick={() => {
              navigate(-1);
            }}
          >
            <Text>{t('topbar.back')}</Text>
            {!isPc && (
              <Box transformOrigin={'center'} transform="rotate(90deg)">
                <ArrowDownIcon color={isDark ? '#20273d' : 'white'} />
              </Box>
            )}
          </Button>
        </Box>
      </Box>

      {userRoles && pathname === '/' && (
        <Menu>
          <MenuButton
            color={'#fff'}
            pos="absolute"
            top={5}
            right={'140px'}
          ></MenuButton>
          <MenuList overflowY="auto" maxH={'300px'}>
            <Text px={3} fontWeight="bold" fontSize={20}>
              {userInfo?.name}
            </Text>
            <Text px={3} mb={5} opacity={0.5} fontSize={14}>
              {userRoles.length == 0
                ? t('topbar.noRolesFound')
                : t('topbar.chooseRole')}
            </Text>

            {userRoles.map((role: any) => (
              <MenuItem
                // color="red.600"
                onClick={() => {
                  setCurrRole(role);
                }}
              >
                {role.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
      <Box
        position="absolute"
        right="50%"
        transform="translateX(50%)"
        marginTop="60px"
        onClick={() => {
          navigate('/home');
        }}
        zIndex={111}
      >
        <Box
          transformOrigin={'center'}
          transform={`scale(${isPc ? 1 : 0.7})`}
          cursor={'pointer'}
        >
          <Logo isShowStand={pathname === '/'} isDark={isDark} />
        </Box>
      </Box>
    </Box>
  ) : null;
}
