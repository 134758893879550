const hebrew = {
  translation: {
    onboardingManagement: {
      title: 'ניהול תהליך הרשמה',
      add: 'הוספה',
    },
    adminOnboarding: {
      title: 'ניהול תהליך הרשמה',
      phone: 'מספר טלפון',
      loadPhones: 'טעינת טלפונים',
      remove: 'הסרה',
      errorTitle: 'שגיאה',
      errorText: 'ניתן להעלות רק קבצי אקסל',
    },
    sanctions: {
      title: 'איתור סנקציות בינלאומיות',
      search: 'חיפוש',
      placeholder: 'חיפוש לפי שם מלא או חלקי באנגלית בלבד...',
      query: 'חפש אדם',
      errorTitle: 'שגיאה',
      errorText: 'ניתן להעלות קובץ אקסל בלבד',
      setEurope: 'עדכן סנקציות אירופאיות',
      setAmerica: 'עדכן סנקציות אמריקאיות',
      europeName: 'רשימת שמות אירופאית',
      americaName: 'רשימת שמות אמריקאית',
      minChars: 'חובה להזין לפחות 3 תווים',
      loadData: 'טוען נתונים...',
      noResults: 'לא נמצאו תוצאות.',
    },
    modifyQuestionnaire: {
      title: 'עריכת שאלון',
      name: 'שם השאלון',
      questionContent: 'תוכן השאלה',
      options: 'אפשרויות',
      option: 'אפשרות',
      question: 'שאלה',
      addQuestion: 'הוסף שאלה',
      addOption: 'הוסף אופציה',
      deleteQuestion: 'מחק שאלה',
      deleteOption: 'מחק אופציה',
      save: 'שמירה',
    },
    onboarding: {
      header: 'ברוך הבא למערכת הדיווחים שלנו',
      personalInformation: 'מידע אישי',
      toc: 'תנאי שימוש',
      form: 'טופס',
      name: 'שם מלא',
      roles: 'תפקידים',
      next: 'הבא',
    },
    assistant: {
      waiting: 'אנא המתן',
      placeholder: 'הקלד הודעה',
      title: 'עוזר דיגיטלי',
      errorTitle: 'שגיאה',
      errorText: 'באופן זמני התקשורת על הבוט עמוסה, נא לנסות שוב תוך כמה דקות.',
    },
    risksFiles: {
      delete: 'מחיקה',
      upload: 'העלה',
      download: 'הורדה',
      file: 'קובץ',
      riskName: 'שם הסיכון',
      title: 'ניהול בוט',
      restart: 'בניית בוט מחדש',
      restartTooltip:
        'חשוב מאוד: בניית בוט מחדש היא פעולה חריגה ועוצמתית. הפעולה מתבצעת בכל לילה באופן אוטומטי, אבל לחיצה על הכפתור מאפשרת לבצע אותה מיידית ולעדכן את כל הנתונים של הבוט. הפעולה צורך הרבה טוקנים מ-OpenAI (וכיוצא מזה - כסף). חשוב להשתמש בזהירות רבה ולהמתין כ-5 דקות לפני שימוש חוזר בבוט.',
      errorTitle: 'שגיאה',
      errorText: 'ניתן להעלות רק קבצי וורד',
      add: 'הוספה',
      replace: 'החלפה',
    },
    month: {
      january: 'ינואר',
      february: 'פברואר',
      march: 'מרץ',
      april: 'אפריל',
      may: 'מאי',
      june: 'יוני',
      july: 'יולי',
      august: 'אוגוסט',
      september: 'ספטמבר',
      october: 'אוקטובר',
      november: 'נובמבר',
      december: 'דצמבר',
    },
    calendar: {
      sunday: 'א',
      monday: 'ב',
      tuesday: 'ג',
      wednesday: 'ד',
      thursday: 'ה',
      friday: 'ו',
      saturday: 'ש',
    },
    intervalPicker: {
      minute: 'דקות',
      day: 'ימים',
      week: 'שבועות',
      month: 'חודשים',
      year: 'שנים',
      taskDetails: 'פרטי המשימה',
      startDate: 'תאריך התחלה',
      hour: 'שעה',
      back: 'חזור',
      every: 'כל',
      save: 'שמירה',
    },
    rangePicker: {
      minute: 'דקות',
      day: 'ימים',
      week: 'שבועות',
      month: 'חודשים',
      year: 'שנים',
      filterDates: 'סינון תאריכים',
      save: 'שמירה',
    },
    sidebar: {
      role: 'תפקיד',
      sanctions: 'סנקציות בינלאומיות',
      main: 'ראשי',
      training: 'הדרכות',
      history: 'היסטוריה',
      about: 'אודות',
      termsOfService: 'תנאי שירות',
      privacyPolicy: 'מדיניות פרטיות',
      contactUs: 'צור קשר',
      signOut: 'התנתקות',
    },
    topbar: {
      back: 'חזור',
      noRolesFound: 'לא נמצאו תפקידים',
      chooseRole: 'בחר תפקיד',
    },
    about: {
      title: 'אודות חברת SBY',
      contents: `חברת SBY (WE STAND BY YOU) הוקמה בשיתוף עם בני כץ חברת עורכי דין על מנת לתת מענה למנהלים אשר חשופים לאירועים פליליים רבים שלרוב כלל אינם מודעים לקיומם. בשנים האחרונות התרחבה האכיפה הפלילית ונפתחו מספר רב של חקירות מנכ"לים, דירקטורים ובעלי תפקידים, חלקן תוך ביצוע מעצר. הפסיקה הרחיבה את אחריות המנכ"ל וקבעה כי הוא מחויב לבצע כל הדרוש על למנוע אירועים פליליים. חברת SBY מתמחה במתן מעטפת אישית למנהלים באמצעות איתור החשיפות והסיכונים על ידי ביצוע סקר מקיף המתבצע בהתאמה מדויקת לסיכונים האישיים הפליליים של המנכ"ל ובהתאמה לאופי הפעילות של החברה. הצגת הסקר למנכ"ל בדיסקרטיות מלאה ובניית מערך פתרונות וטיפול מתמשך בחשיפה הפלילית ב"תוכנית 18"- הטמעה וליווי אישי באמצעות שימוש באמצעים מתקדמים.`,
    },
    addTasksForm: {
      deleteFile: 'למחוק את הקובץ',
      deleteWarning: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע',
      cancel: 'ביטול',
      delete: 'מחיקה',
      error: 'שגיאה',
      invalidDate: 'תאריך לא תקין',
      success: 'הצלחה',
      taskListCreated: 'רשימת משימות נוצרה בהצלחה',
      addList: 'הוסף רשימה',
      job: 'משרה',
      roles: 'תפקידים',
      tasks: 'משימות',
      noRisks: 'אין סיכונים לחברה',
      addRisk: 'הוסף סיכון',
      taskName: 'שם המשימה',
      taskDescription: 'הזן תיאור למשימה',
      addFile: 'הוסף קובץ',
      taskDetails: 'פרטי משימה',
      file: 'קובץ',
      download: 'הורדה',
      addTask: 'הוסף משימה',
      save: '',
    },
    companies: {
      companies: 'חברות',
      addCompany: 'הוסף חברה',
      name: 'שם',
      field: 'שדה',
      performance: 'ביצועים',
    },
    contact: {
      contact: 'יצירת קשר',
      instructions: `מערכת SBY מסייעת לחברות להישאר מוגנים מפני חשיפה אישית פלילית לבעלי התפקידים בתחומים המשפטיים השונים, תוך ליווי מלא מאיתנו. במידה ויש עניינים מהותיים שאתם לא בטוחים לגביהם, כדאי ורצוי להתייעץ אתנו. בנוסף הצוות שלנו פעל רבות על מנת לאפשר לכם פלטפורמה פשוטה ויעילה, אך אם בכל זאת משהו לא ברור או נתקלתם בבעיה, אתם מוזמנים ליצור איתנו קשר באחד מהאמצעים הבאים`,
      address: 'דרך מנחם בגין 144, מגדל מידטאון, קומה 45, תל אביב',
    },
    dashboard: {
      error: 'שגיאה',
      unauthorized: 'אינך מורשה לגשת לעמוד זה',
      prevailTogether: 'ביחד ננצח',
      welcome: 'ברוך הבא',
      training: 'הדרכות חשובות',
      viewTraining: 'צפייה בהדרכות',
      myTasks: 'האיזור שלי',
      show: 'הצג',
      performance: 'מד ביצועים',
      actions: 'נקיטת פעולה',
      processing: 'מחשב',
      addTraining: 'הוספת הדרכה',
      companies: 'חברות',
      addCompany: 'הוסף חברה',
      viewCompanies: 'ראה חברות',
    },
    exposure: {
      performance: 'ביצועים',
      generalPerformance: 'מד ביצועים כללי',
      processing: 'מחשב',
      risks: 'סיכונים',
      check: 'בדיקה',
    },
    exposureDetailed: {
      employee: 'עובד',
      progress: 'תהליך',
      role: 'שיוך',
    },
    login: {
      pleaseLogin: 'אנא התחברו על מנת להמשיך',
      enterMfaCode: 'הכנס קוד אימות',
      enterEmailPass: 'אנא הזן כתובת מייל וסיסמה',
      hello: 'שלום',
      enterCode: 'הזן קוד',
      codeSent: 'נשלח קוד אימות למספר',
      mfaCall: 'שליחת קוד בשיחה',
      mfaDeferred: 'זמין בעוד',
      seconds: 'שניות',
      phoneNumber: 'מספר טלפון',
      rememberMe: 'זכור אותי',
      loginButton: 'התחברות',
    },
    modifyCompany: {
      onboardingQuestionnaireManagement: 'ניהול שאלון כניסה',
      isAfterOnboarding: 'לאחר שאלון כניסה',
      onboardingQuestionnaire: 'שאלון כניסה',
      invalidName: 'שם לא תקין',
      enterCompanyName: 'אנא הזן את שם החברה',
      enterRiskNames: 'אנא הזן שמות לכל הריסקים',
      uniqueRiskNames: 'על שמות הריסקים להיות ייחודים',
      enterRoleNames: 'אנא הזן שמות לכל התפקידים',
      uniqueRoleNames: 'על שמות התפקידים להיות ייחודים',
      invalidEmployee: 'עובד לא תקין',
      invalidEmployeeName: 'אחד משמות העובדים אינו תקין',
      invalidPhoneNumber: 'מספר פלאפון לא תקין',
      enterPhoneNumbers: 'אנא הזן מספר פלאפון עבור כל העובדים',
      editCompany: 'ערוך חברה',
      addCompany: 'הוסף חברה',
      companyDetails: 'פרטי חברה כללי',
      companyName: 'שם החברה',
      field: 'שדה',
      siteLink: 'קישור לאתר',
      active: 'פעיל',
      language: 'שפה',
      risks: 'סיכונים',
      riskName: 'שם הסיכון',
      deleteRisk: 'למחוק את הסיכון',
      deleteWarning: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע',
      enterPassword: 'הקש סיסמה',
      cancel: 'ביטול',
      delete: 'מחיקה',
      invalidPassword: 'סיסמא שגוייה',
      addRisk: 'הוסף סיכון',
      roles: 'תפקידים',
      showRiskProgress: 'הצג התקדמות סיכונים',
      showNewsletters: 'הצג מודעות',
      reporting: 'דיווח',
      addRole: 'הוסף תפקיד',
      employees: 'עובדים',
      addEmployee: 'הוסף עובד',
      name: 'שם',
      phone: 'טלפון',
      role: 'תפקיד',
      chooseRole: 'בחר תפקיד',
      deleteEmployee: 'למחוק את העובד',
      employeeName: 'שם העובד',
      moreDetails: 'פרטים נוספים',
      approve: 'אשר',
    },
    modifyNewsletter: {
      deleteFile: 'למחוק את הקובץ',
      deleteWarning: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע',
      cancel: 'ביטול',
      delete: 'מחיקה',
      addTraining: 'הוספת הדרכה',
      generalDetails: 'פרטים כללים',
      titleDescription: 'תיאור כותרת',
      video: 'סרטון',
      picture: 'תמונה',
      linkTo: 'קישור ל',
      title: 'כותרת',
      choosePicture: 'בחר תמונה',
      deletePicture: 'מחק תמונה',
      recipients: 'נמענים',
      companyName: 'שם חברה',
      selectAll: 'בחר הכל',
      content: 'תוכן',
      enterContent: 'הכנס תוכן',
      save: 'שמירה',
    },
    newsletter: {
      deleteNewsletter: 'למחוק את המודעה',
      deleteWarning: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע',
      cancel: 'ביטול',
      delete: 'מחיקה',
      hello: 'שלום',
      peaceOfMind: 'השקט שבידע',
      addTraining: 'הוספת הדרכה',
      edit: 'ערוך',
      editing: 'עריכה',
    },
    newsletterView: {
      deleteNewsletter: 'למחוק את המודעה',
      deleteWarning: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע',
      cancel: 'ביטול',
      delete: 'מחיקה',
      editing: 'עריכה',
    },
    privacyPolicy: {
      title: 'מדיניות פרטיות SBY',
      conditions: `מדיניות פרטיות זו (להלן: "המדיניות") מתארת למשתמש (להלן: "המשתמש") את אופן האיסוף, דרך השימוש והשיתוף של המידע האישי אשר נמסר על ידך ו/או נאסף עליך באמצעות מערכת PRC (להלן: "המערכת" ו/או "PRC") ו/או באמצעותך. הזנת פרטיך במערכת ו/או מידע אשר בחרת לחלוק עם חברת SBY בע"מ ("החברה"), הכל בהתאם למפורט במדיניות להלן. המדיניות מהווה חלק בלתי נפרד מתנאי השימוש של האתר. המדיניות כתובה בלשון זכר מטעמי נוחות בלבד, והיא מיועדת לנשים וגברים כאחד. אנו ממליצים לכם לקרוא את מדיניות הפרטיות בעיון ואם אינכם מסכימים לתנאי המדיניות, אנא הימנעו מהשימוש במערכת PRC. המשך שימוש על ידכם יהווה הסכמתכם למדיניות השימוש.`,
      contents: `1. חוקים ורגולציה מקומית

1.1 מדיניות פרטיות זו מטרתה לעמוד בחוקים ובתקנות אשר חלים בכל מדינה שממנה נאסף המידע האנונימי והמידע האישי, כמתואר במדיניות פרטיות זו, לרבות החוק להגנת הפרטיות התשמ"א-1981 (להלן: "החוק") ותקנות הגנת הפרטיות (אבטחת מידע), התשע"ז-2017 (להלן:"התקנות").

2. המידע הנאסף על ידי החברה והשימוש בו

2.1 מידע אנונימי שנאסף: בעת שימוש במערכת, עשויה החברה לאסוף מידע הנוגע לשימוש במערכת אשר אינו מאפשר זיהוי של היחיד ו/או חשיפת זהותם של המשתמשים כגון מידע מצטבר וניתוח על דפוסי שימוש (להלן: "מידע אנונימי") זאת לשם שיפור השירותים והעשרת תוכן המערכת, איכותו וטיב השירותים הניתנים למשתמשים במערכת על ידי החברה וכן לשם שימוש במידע הנאסף לצרכי ביצוע סטטיסטיקות, ביצוע מחקר ועריכת ניתוחים אנונימיים. החברה רשאית לאסוף, לעשות שימוש ולפרסם מידע אנונימי, ולחשוף אותו לצדדים שלישיים, לשם אספקת, שיפור ופרסום המערכת והשירותים של החברה. מידע אנונימי עשוי להישמר לצמיתות בארכיוני החברה לצורך שימוש עתידי.
2.2 מידע אישי שנאסף: מידע זה מאפשר זיהוי של המשתמש באופן מלא או במאמץ סביר באמצעות חיבור מידע נוסף (להלן: "מידע אישי") החברה עשויה לאסוף מידע אישי שהנכם מזינים ומוסרים במערכת (כגון: שם מלא, מספר זהות, כתובת דואר אלקטרוני, תאריך לידה וכיו"ב.
2.3 השימוש במידע האישי: החברה אוספת את המידע האישי לצורך המטרות הבאות (כולן או חלקן):
2.4 לשם אספקת השירותים הניתנים למשתמש על ידי החברה במסגרת המערכת ו/או שימוש ו/או שיפור השירותים במערכת.
2.5 לשם תמיכה ומתן מענה לשאלות המשתמשים ותקשורת עם המשתמש במערכת.
2.6 לשם זיהוי המשתמש בעת פנייה לחברה ו/או למי מטעמה.
2.7 לשם קשרי לקוחות כולל תקשורת במטרה לעדכן את המשתמשים בנוגע לעדכונים האחרונים של החברה וכן לספק למשתמשים מידע ביחס לתוכן שעשוי לעניין את המשתמשים.
2.8 מידע טכני מהמכשיר הסלולרי ו/או מחשב של המשתמש לשם מניעת הונאות.
2.9 לשם עמידה בהוראות הדין, חובות רגולטוריות ודרישות של רשות ממשלתית או צו משפטי.
2.10 לכל מטרה אחרת המפורטת במדיניות או בתנאי השימוש של איזה מהשירותים הרלוונטיים במערכת.
2.11 המשתמש מתחייב למסור, אך ורק, פרטים נכונים, מדויקים ומלאים ומאשר בזאת את נכונות הפרטים שנמסרו על ידו. יובהר, על המשתמש לא חלה חובה למסור מידע כלשהו לחברה באמצעות המערכת אולם ככל שלא יימסר המידע ייתכן והחברה ולא תוכל לספק שירותים (כולם או חלקם) לאותו משתמש.
2.12 ככל שהחברה תבקש להוסיף שימושים ו/או לאסוף מידע אישי נוסף היא תבקש את הסכמתו מראש של המשתמש, אולם, ככל שאישור זה לא יינתן, ייתכן והחברה לא תוכל לספק שירותים (כולם או חלקם) לאותו משתמש.
2.13 מאגרי מידע. המשתמש מצהיר כי ידוע לו שמידע אשר נמסר על ידי המשתמש נאסף על ידי החברה כמפורט במדיניות זו, מצטבר בבסיסי הנתונים של החברה ובמאגר המידע של החברה ו/או במאגר של ספקי שירות מטעמה, והינם ניתנים לעיון של כל משתמש אשר נרשם לשירותי החברה ושילם להיות מנוי לשירותים.
2.14 ידוע למשתמש כי החברה תחזיק, תנהל ותשתמש במידע שבמאגר המידע לשם לקידום פעולותיה, כולל פעולותיה עם גופים אחרים, והמשתמש מסכים לכך ומצהיר כי אין ולא תהיה לו כל טענה כנגד החברה ו/או מי מטעמה עקב כך, ובלבד שהשימוש במידע יהיה בהתאם להוראות מדיניות פרטיות זו

3. שיתוף צדדים שלישיים במידע החברה מעבירה מידע לצדדים שלישיים רק במקרים המפורטים להלן:

3.1 ככל שהמשתמש נתן את הסכמתו המפורשת.
3.2 לרשויות המדינה בהתאם לסמכויות המוקנות להן על פי דין.
3.3 המידע האישי יועבר על ידי החברה לחברות קשורות לרבות חברות בנות, מיזמים משותפים או חברות אחרות תחת שליטה משותפת או עסקים המנהלים עסק הקשור לשירותים.
3.4 במקרה של מחלוקת משפטית בין המשתמש לבין החברה שתחייב חשיפת פרטי המשתמש.
3.5 אם המשתמש יבצע במערכת פעולות שבניגוד לדין ו/או באופן שיפגע בתפקודו התקין.
3.6 אם יתקבל צו שיפוטי המורה למסור את פרטי המשתמש או המידע אודותיו לצד שלישי.
3.7 בכל מקרה של חשד להונאה או בעיות אבטחה.
3.8 צדדי ג' עשויים לאסוף מידע ו/או לעבד ו/או לשמור ו/או לשתף את המידע שלכם כיוצא באלה ואולם פעולות אלו מתבצעות בכפוף לתנאי שימוש ומדיניות פרטיות של אותם צדדי ג'. לפיכך, החברה אינה אחראית לפעולות כאמור ו/או למדיניות פרטיות ותנאי השימוש החלים על פעולות אלו המתבצעות על ידי צדדי ג.
3.9 המשתמש רשאי להודיע לחברה בחוזר לדוא"ל כי הוא מבטל כל הסכמה קודמת שניתנה לחברה על ידו לשימוש במידע האישי שלו, כפי שמפורט במדיניות הפרטיות ובמקרה זה תחדל החברה מלעשות שימוש במידע האישי וממתן השירותים.

4. אבטחת מידע החברה פועלת על פי נהלים המקובלים בתעשייה לצורך אבטחת המידע של כל משתמשיה. יחד עם זאת, אין אמצעים אלקטרוניים באינטרנט אשר יכולים לאבטח ב - 100% את המידע השמור בשרתים של החברה. לאור האמור החברה מיישמת כללים ונהלים לצורך הגנה על המידע האישי שלך. חברה לא יכולה להתחייב כי המידע שבשרתיה לא יאבד / ייפגם / יושמד / ייפרץ / יתגלה.

5. שמירת מידע

5.1 המידע עליך מאוחסן במאגרי מידע המנוהלים ע"י החברה ו/או על ידי צד שלישי מטעמה בכפוף לתקנות הגנת הפרטיות (אבטחת מידע), התשע"ז- 2017.
5.2 מאגרי המידע עשויים להימצא מחוץ לגבולות מדינת ישראל. בעצם השימוש במערכת, המשתמש מסכים להעברת המידע עליו ולשמירתו מחוץ לגבולות ישראל.

6. דיוור ישיר ופרסומת

6.1 החברה עשויה לפנות אליכם ולהציע לכם הטבות ודברי פרסומת ו/או מידע אחר ו/או פנייה באמצעות דיוור ישיר כהגדרתו בסעיף 17 ג' לחוק הגנת הפרטיות, תשמ"א – 1981, ו/או דברי פרסומת כפי שמוגדר בסעיף 30א (ב) לחוק התקשורת (בזק ושירותים) התשמ"ב 1982.
6.2 ככל שהחברה תבצע דיוור או פרסומת כאמור תהיה רשאי להורות לחברה להסיר אותו מרשימות הדיוור שלנו וזאת על ידי פנייה אל החברה בכתובת שתצוין בהודעת הדיוור ו/או פרסומת.

7. עדכונים ותיקונים למדיניות

7.1 המדיניות עשויה להשתנות מעת לעת, ובאחריותכם להתעדכן בגרסה האחרונה והמחייבת אשר התפרסמה במערכת PRC. השימוש המתמשך שלכם במערכת, לאחר שינויים במערכת כאמור, מהווה בזאת את הסכמתכם לתיקונים האלה למדיניות הפרטיות ואת הסכמתכם להיות מחויבים לתנאים שגובשו במסגרת התיקונים האלה.

8. Cookies

8.1 לצורך תפעולה השוטפת והתקינה של מערכת PRC ובכלל זה לצורך איסוף נתונים סטטיסטים אודות השימוש ואימות פרטים עשויה החברה לעשות שימוש ב"עוגיות" (קבצי טקסט שהדפדפן שלך יוצר לפי פקודה ממחשבי המערכת).
8.2 המערכת עושה שימוש בעוגיות למספר מטרות מרכזיות:
8.3 עוגיות חיוניות: לשם התנהלות תקינה של המערכת, לרבות זיהוי המשתמש בעת כניסתו ו/או הרשמתו למערכת. עוגיות אלו הינן הכרחיות לפעילות התקינה של המערכת.
8.4 עוגיות מעקב ביצועים: לשם איסוף מידע אנונימי, סטטיסטי על פעילות המשתמש במערכת (כגון: מידע על העמודים הנצפים ביותר, זמני שהייה בכל עמוד) לשם שיפורה של המערכת.
8.5 עוגיות פונקציונאליות (העדפות): לשם התאמת ברירת המחדל להעדפות המשתמש (כגון: בחירת שפת הממשק, סיסמאות, פרטי הרשמה). ללא עוגיות אלו, הפונקציונאליות של המערכת עלולה להיפגע.
8.6 ניתן לשנות את הגדרות הדפדפן ובכך לא לאפשר למערכת PRC לנהל עוגיות. עם זאת, סביר ביותר שחסימת השימוש בחלק או בכל העוגיות לא תאפשר מהלך תקין של גלישה ושימוש במערכת.`,
    },
    reports: {
      reportsList: 'רשימת דוחות',
      history: 'היסטוריה',
      downloadReport: 'הורדת דוח',
      companyName: 'שם החברה',
      chooseCompany: 'בחר חברה',
      dateRange: 'טווח תאריכים',
      date: 'תאריך',
      employeeName: 'שם העובד',
      overview: `דוח זה משמש להצגת דוחות יומיים אודות סימון משימות במערכת, הערות
         קשורות וקבצים שצורפו אליהן. הדוח מציג את המידע בדבר הסטטוס של כל
         משימה במועד הנבחר והמצוין על גבי הדוח. הדוח נשמר וננעל על בסיס יומי
         ואינו ניתן לעריכה ע"י שום גורם.`,
    },
    tasklists: {
      lists: 'רשימות',
      addList: 'הוסף רשימה',
      trustee: 'נאמן',
      employee: 'עובד',
      endDate: 'תאריך סיום',
      tasks: 'משימות',
      unassigned: 'לא משוייך',
      deleteTasksList: 'למחוק את רשימת המשימות',
      deleteWarning: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע',
      cancel: 'ביטול',
      delete: 'מחיקה',
    },
    tasksForm: {
      nextReset: 'המשימה תתחדש בתאריך',
      deleteFile: 'למחוק את הקובץ',
      deleteWarning: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע',
      cancel: 'ביטול',
      delete: 'מחיקה',
      approve: 'אישור',
      error: 'שגיאה',
      tasksApproval: 'אישור משימות',
      appreciation: 'אנחנו מעריכים את ההבנה וההתחייבות שלך.',
      comprehension: 'קראתי היטב והבנתי את כל מה שאישרתי',
      readAndConfirm: 'אנא קרא את המשימות ואשר אותן',
      success: 'הצלחה',
      tasksSent: 'המשימות נשלחו בהצלחה',
      tryAgainLater: 'אירעה שגיאה, אנא נסו שוב מאוחר יותר',
      addComments: 'הוספת הערה ופירוט',
      enterComment: 'הכנס הערה',
      save: 'שמירה',
      planningExecution: 'תוכנית מול ביצוע',
      myTasks: 'איזור אישי',
      formBelongs: 'טופס זה שייך',
      addFile: 'הוסף קובץ',
      additionalDetails: 'פרטים נוספים',
      file: 'קובץ',
      download: 'הורדה',
      comments: 'הערות',
      noTasks: 'אין לך משימות להיום',
    },
    termsOfUse: {
      proceed: 'המשך',
      termsAndCopyright: 'הסכם תנאי שימוש וזכויות יוצרים',
      readAndApprove: 'קראתי ואני מאשר את תנאי השימוש',
      contents: `הגדרות :

החברה – חברת SBY לרבות מנהליה, עובדיה, סוכנים מטעמה ודירקטורים בחברה.

המשתמש- הלקוח לרבות החברה שרכשה את המוצר/השירות, עובדי הלקוחה ו/או מי מטעמה.

צד ג' – משתמש במוצר שאינו לקוח של החברה באופן ישיר. המוצר – מערכת PRC תיקרא גם "האתר" ו/או "השירות"

תוכן – המידע, המסמכים המצגים והתמונות המופיעים במוצר

חוק - חוקי מדינת ישראל

זכויות יוצרים – הזכויות על המוצר

רישיון שימוש – השימוש במוצר מותנה בקבלת רישיון מוגבל בזמן מהחברה.

הסכמה:

הסכמת המשתמש לתנאים ולהגבלות המפורטים להלן מהווה הסכם משפטי מחייב שנערך בין המשתמש (בין אם באופן אישי ובין אם מטעם ישות/חברה) לבין החברה לגבי הגישה והשימוש של המשתמש במוצר – מערכת PRC וכן כל טופס מדיה אחר, ערוץ מדיה, אתר, נייד או אפליקציה לנייד הקשורים, מקושרים או מחוברים אליו אחרת.

הנך מסכים שעם קבלת הגישה למוצר קראת, הבנת והסכמת להיות מחויב לכל התנאים וההגבלות הללו. אם אינך מסכים לכל התנאים וההגבלות הללו, נאסר עליך במפורש להשתמש במוצר ועליך להפסיק את השימוש לאלתר.

התנאים להלן חלים על כל התקשרות בין החברה לבין המשתמש בנוגע לגישה, לשימוש במוצר, לתוכן המוצר ולזכויות היוצרים במוצר והשימוש במוצר מהווה הסכמתך לכל התנאים המפורטים להלן.

זכויות יוצרים:

אלא אם כן צוין אחרת, המוצר וכל קוד המקור, בסיסי הנתונים, פונקציונליות, תוכנה, עיצוב המוצר, הטקסט, פסקאות, אודיו, וידיאו התמונות והגרפיקה להלן: "התוכן", הסימנים המסחריים, סימני השירות והלוגו (להלן:"הסימנים")הינם בבעלותה ו/או בשליטתה הבלעדית של החברה ומוגנים ע"י חוקי זכויות יוצרים וסימנים מסחריים וזכויות קניין רוחני שונות וחוקי תחרות בלתי הוגנים בארץ ובעולם ואין לבצע כל שימוש באחד מאלה ללא אישור מראש ובכתב מהחברה.

התוכן והמידע במערכת מסופקים כמות שהם (As – Is ) והינם למידע ולשימושך האישי בלבד למעט כפי שמצוין במפורש בתנאים ובהגבלות אלה.

אין להעתיק, לשכפל, לצבור, לפרסם מחדש, להעלות, לפרסם, להציג בפומבי, לקודד, לתרגם, לשדר,להפיץ, למכור, להעניק רישיון, לא להעתיק אף חלק מהאתר או תוכן או סימנים. או להשתמש בהם לכל מטרה מסחרית שהיא, ללא אישור מפורש מראש ובכתב מהחברה.

אם הנך זכאי להשתמש במוצר ניתן לך רישיון מוגבל בזמן לגשת למוצר, להשתמש בו ולהוריד או להדפיס עותק של כל חלק מהתוכן שאליו קיבלת גישה אך ורק לשימושך האישי ולא המסחרי.

תנאי שימוש

בשימוש באתר הנך מצהיר ומתחייב:

1. שכל המידע שאתה מעלה למוצר הינו חוקי, נכון ושלם.
2. שכל פרטי הרישום שתשלח הינם נכונים, מדויקים, עדכניים ומלאים.
3. הנך בעל הכשרות המשפטית והסמכות החוקית להסכים ולציית לתנאי הסכם זה.
4. אינך קטין בהתאם לשטח השיפוט בו אתה מתגורר.
5. לא תגש לשירות באמצעות כלים אוטומטיים או לא אנושיים בין אם באמצעות בוט, סקריפט או אחר.
6. לא תשתמש במוצר למטרה בלתי חוקית או לא מורשית.
7. השימוש שלך במוצר לא יפר כל חוק או תקנה.

אם תספק מידע כלשהו שהוא לא נכון, לא מדויק, לא עדכני ו/או לא שלם, יש לחברה את הזכות להשעות או לבטל את חשבונך ולסרב לכל שימוש נוכחי או עתידי במוצר או כל חלק ממנו.

ייתכן שתידרש להירשם למערכת . אתה מסכים לשמור על סודיות הסיסמה שלך ותהיה אחראי לכל שימוש בחשבון ובסיסמה שלך. החברה שומרת לעצמה את הזכות להסיר, לתבוע מחדש או לשנות שם משתמש שבחרת אם נקבע, לפי שיקול דעתה הבלעדי, אם שם משתמש כזה אינו הולם, מגונה או מעורר התנגדות בדרך אחרת.

סיום שימוש במוצר:

תנאים והגבלות אלה יישארו בתוקף כל עוד אתה משתמש באתר. מבלי להגביל כל תנאי אחר של תנאים והגבלות אלה, החברה שומרת לעצמה את הזכות, לפי שיקול דעתה הבלעדי וללא הודעה או אחריות, לדחות את הגישה והשימוש במוצר (כולל חסימת כתובת IP מסויימת) עבור כל כתובת IP. ללא כל סיבה, לרבות ללא הגבלה בגין הפרה של כל מצג, אחריות או אמנה הכלולים בתנאים והגבלות אלה או של כל חוק או תקנה רלוונטיים. החברה עשויה להפסיק את השימוש או ההשתתפות באתר או למחוק את חשבון וכל תוכן או מידע שפרסמת בכל עת, ללא אזהרה, לפי שיקול דעתה הבלעדי.

בנוסף לסיום או השעיית חשבונך, החברה שומרת לעצמה את הזכות לנקוט בצעדים משפטיים מתאימים, לרבות, ללא הגבלה, תביעה אזרחית, פלילית או צו מניעה.

שינויים

החברה שומרת לעצמה את הזכות לשנות ו/או להסיר את תוכן האתר בכל עת ומכל סיבה לפי שיקול דעתה הבלעדי ללא הודעה מוקדמת. עם זאת, אין לחברה מחויבות לעדכן מידע כלשהו באתר שלה. כמו כן, החברה שומרת לעצמה את הזכות לשנות ו/או להפסיק את השימוש במוצר או חלקו ללא הודעה מוקדמת בכל עת. החברה לא תהא אחראית כלפי המשתמש ו/או כלפי צד שלישי כלשהו בגין כל שינוי, שינוי מחיר, השעיה או הפסקה של המוצר.

החברה אינה יכולה להבטיח שהאתר יהיה זמין בכל עת. החברה עשויה להיתקל בבעיות חומרה, תוכנה או אחרות או צורך לבצע תחזוקה הקשורה לאתר, וכתוצאה מכך להפרעות, עיכובים או שגיאות. החברה שומרת לעצמה את הזכות לשנות, לעדכן, להשעות, להפסיק, או לשנות באופן אחר את המוצר/המערכת בכל עת ומכל סיבה ללא הודעה למשתמש. המשתמש מסכים שאין לחברה אחריות כלשהי לכל אובדן, נזק או אי נוחות שנגרמו מחוסר יכולתו לגשת לאתר או להשתמש בו במהלך כל השבתה או הפסקת פעילות האתר. שום דבר בתקנון זה לא יתפרש כמחייב את החברה לתחזק ולתמוך במוצר או לספק כל תיקונים, עדכונים או מהדורות בקשר אליו.

כתב ויתור

השימוש במוצר PRC ובשירותים הנלווים לו הינם למטרה חוקית בלבד ובמסגרת המגבלות המוטלות בחוק ובאחריותו הבלעדית של המשתמש ולחברה לא תהיה כל אחריות ישירה ו/או עקיפה בקשר למוצר ולשימוש המשתמש במוצר, למצגים ולתוכן, לדיוק בתוכן ולשלמותו המוצג במערכת לרבות ללא הגבלה לנזקים ו/או להפסדים שייגרמו למשתמש ו/או לצד שלישי כתוצאה משימוש במוצר. מבלי לגרוע מהאמור לעיל, החברה לא תשא באחריות לכל:

מבלי לגרוע מהאמור לעיל, החברה לא תשא באחריות לכל:

1. תקלה
2. לכל שימוש במוצר שאינו חוקי לרבות לשימוש המהווה הפרת זכויות יוצרים.
3. לנכונות המידע שיעלו המשתמשים במוצר ו/או לשלמותו של המידע במוצר ו/או לשלמות המידע באתרים/במסמכים מקושרים למוצר ולא תשא באחריות לגבי טעויות, שגיאות ואי דיוקים בתוכן שהועלה ומופיע במוצר וכל נזק שהוא שיגרם למשתמש ו/או לצד שלישי הנובע משימוש באתר.
4. לכל גישה בלתי מורשית למוצר ו/או לשרתי החברה ולכל המידע האישי ו/או הפיננסי המאוחסן במוצר.
5. כל הפרעה או הפסקה של שידור לאתר או ממנו.
6. לבאגים, וירוסים, סוסים טרויאנים או לכל תוכנה זדונית אחרת אשר עשויים להיות מועברים למערכת או דרכה ע"י כל צד שלישי.
7. כל שגיאה או השמטה בכל תוכן וחומרים או עבור כל אובדן או נזק מכל סוג שנגרמו כתוצאה מהשימוש בכל תוכן שפורסם, מועבר או זמין בדרך אחרת דרך המערכת.
8. למצגים המוצגים באתר, לדיוק או שלמות המצגים ו/או התוכן של אתרי אינטרנט כלשהם המקושרים לאתר /מידע כלשהוא/ מסמכים באתר /תוכן אחר באתר.
9. לנזק ו/או הפסד שיגרמו למשתמשים ו/או לצד שלישי כתוצאה מחוסר דיוק/שלמות התוכן ו/או המצגים שהועלו למוצר.
10. לכל תקלה ו/או נזק מכל סוג שהוא שיגרמו כתוצאה מגישת המשתמש לאתר ושימוש במוצר.
11. לתוכן ו/או שירות שהועלה למוצר ע"י צד שלישי לרבות קישורים לאתרים ולשירותים אחרים ו/או חיבורים לאפליקציות מובייל ואחרות.
12. החברה לא תהיה צד בכל צורה ולא תהיה אחראית לניטור ולניהול כל פעילות בין הלקוח לבין צדדים שלישיים לרבות שירותים ומוצרים של צדדים שלישיים.

המוצר והשימוש בו אינו מהווה תחליף ליעוץ משפטי.

הגבלת אחריות

בשום מקרה לא תהיה החברה אחראית לרבות מנהליה, עובדיה, סוכניה ודירקטוריה כלפי המשתמש ו/או כלפי כל צד שלישי לכל נזק ישיר ו/או עקיף , אובדן נתונים או נזקים אחרים הנובעים משימוש באתר גם אם החברה הודיעה לך על אפשרות לנזקים כאלה. על אף כל האמור במסמך זה , חבות החברה כלפי משתמש תוגבל בכל עת לסכום ששולם ע"י המשתמש לחברה.

שיפוי

הנך מסכים לשפות את החברה, העובדים ונושאי המשרה בה מפני ונגד כל אובדן, נזק חבות, תביעה או דרישה לרבות עמלות והוצאות בגין כל נזק שייגרם לחברה על ידך או ע"י צד שלישי עקב או הנובעות מ:

1. שימוש במוצר
2. הפרה של תנאים והגבלות אלה
3. כל הפרה של המצגים ואחריותך המפורטים בתנאים והגבלות אלה
4. הפרת זכויות של צד שלישי לרבות אך לא רק זכויות קניין רוחני.
5. כל מעשה מזיק כלפי משתמש אחר במוצר איתו התחברת דרך השימוש במערכת.

על אף האמור לעיל, החברה שומרת על זכותה לקבל על עצמה על חשבון המשתמש את ההגנה והשליטה הבלעדית בכל עניין שבגינו אתה נדרש לשפות את החברה או מי מטעמה והנך מתחייב לשתף פעולה עם החברה על חשבונך. החברה תעשה מאמצים סבירים להודיע לך על על תביעה, פעולה או הליך שכפופים לשיפוי זה עת היוודע לחברה על כך.

שמירת מידע

החברה תשמור נתונים מסוימים שהמשתמש יעביר למערכת לצורך ניהול ביצועי המערכת וכן נתונים הנוגעים לשימוש המשתמש באתר. למרות שהחברה מבצעת גיבויים שוטפים של נתונים, המשתמש הינו האחראי הבלעדי לכל הנתונים שהוא מעביר או הקשורים לכל פעילות שביצע באמצעות המערכת . המשתמש מסכים שלא תהיה לחברה אחריות כלפיו לכל אובדן או השחתה של כל מידע כזה, והוא מוותר בזאת על כל זכות תביעה נגד החברה הנובעת מכל אובדן או השחתה כאמור של נתונים כאלה.

סמכות שיפוט

תנאים אלה יהיו כפופים ויוגדרו בהתאם לחוקי ישראל. החברה והמשתמש מסכימים באופן בלתי הפיך כי לבתי המשפט בישראל תהיה סמכות שיפוט בלעדית להכריע בכל מחלוקת שעלולה להתעורר בקשר לתנאים אלה. מוסכם כי סמכות השיפוט המקומית תהיה לבתי המשפט בתל אביב.

שונות

תקנון זה וכל מדיניות או כללי פעולה המתפרסמים על ידינו במערכת או ביחס למערכת מהווים את ההסכם בין המשתמש לחברה. כישלון החברה לממש או לאכוף כל זכות או הוראה של תנאים והגבלות אלה לא יפעל כוויתור על זכות או הוראה כאמור. תנאים והגבלות אלה פועלים במלוא המידה המותרת על פי חוק. החברה רשאית להעביר את כל זכויותיה וחובותיה לאחרים בכל עת. החברה לא תהא אחראית ולא תשא בחבות לכל אובדן, נזק, עיכוב או אי פעולה שנגרמו מכל סיבה שהיא מעבר לשליטתה הסבירה. אם הוראה כלשהי או חלק מהוראה בתנאים וההגבלות הללו נקבעה כבלתי חוקית, בטלה או בלתי ניתנת לאכיפה, הוראה זו או חלק מההוראה נחשבת כניתנת להפרדה מהתנאים וההגבלות הללו ואינה משפיעה על התקפות והאכיפה של כל השאר הוראות.

לא נוצרו בין החברה לבין הלקוח מיזם משותף, שותפות, העסקה או יחסי סוכנות כתוצאה מתנאים והגבלות אלה או משימוש באתר. המשתמש מסכים שתנאים והגבלות אלה לא יתפרשו נגד החברה מתוקף עריכתם. המשתמש מוותר בזאת על כל הגנות העומדות לו בהתבסס על הטופס האלקטרוני של תנאים והגבלות אלה והעדר חתימה מצד הצדדים על ביצוע תנאים והגבלות אלה.

החברה לא תישא באחריות לכל צעד משפטי שננקט נגד המשתמש במקרים של מעשים בלתי חוקיים, על ידי הרשויות או כל צד ג'.`,
    },
  },
};

export default hebrew;
