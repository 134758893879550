import {
  Box,
  Button,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserService from '../../services/user-service';
import { LanguageContext } from '../../locales/LocaleContext';
import style from '../../assets/style.json';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import CompanyService from '../../services/company-service';
import { TrashIcon } from '../../assets/TrashIcon';
import { MenuIcon } from '../../assets/MenuIcon';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';

function OnboardingManagement() {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<any>({});
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const navigate = useNavigate();
  const [onboardingList, setOnboardingList] = useState<any[]>([]);

  const { isLoading, setIsLoading } = useContext(LanguageContext);

  useEffect(() => {
    load();
  }, []);

  const remove = async (id: string) => {};

  const load = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const [user, list] = await Promise.all([
        UserService.info(),
        CompanyService.getOnboardingList(),
      ]);
      setUserInfo(user);
      setOnboardingList(list);
    } finally {
      setIsLoading(false); // Set loading state to false after data is fetched
    }
  };

  const deleteOnboarding = async (id: string) => {
    try {
      await CompanyService.deleteOnboardingQuestionnaire(id);
      load();
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      py={20}
      centerContent
    >
      {isLoading ? (
        <>
          <Flex justify="center">
            <Skeleton height="30px" width="200px" borderRadius="md" />
          </Flex>
        </>
      ) : (
        <>
          <Text
            color={'white'}
            dir="rtl"
            marginBottom={'0px'}
            fontWeight={500}
            fontSize={22}
          >
            {t('onboardingManagement.title')}
          </Text>
        </>
      )}

      <Box
        rounded={'10px'}
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        mt={10}
        maxW={style['pc-view']}
        w="100%"
        paddingInline={'28px'}
        bg={'white'}
        minHeight="380px"
      >
        <Box display={'flex'} flexDirection="row-reverse" width={'100%'}>
          {isLoading ? (
            <>
              <Skeleton
                height="40px"
                width="200px"
                borderRadius="3xl"
                marginBlock={'28px'}
              />{' '}
            </>
          ) : (
            <>
              {' '}
              {userInfo.companyId === '123' && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => navigate('/admin/modify-questionnaire')}
                  marginBlock="28px"
                  width={'188px'}
                  gap="8px"
                  height="44px"
                  rounded={'22px'}
                  bg={'white'}
                  color="#3FB2D2"
                  border={'1px solid #3FB2D2'}
                >
                  <Text fontSize={'18px'} fontWeight="600">
                    {t('onboardingManagement.add')}
                  </Text>
                  <CirclePlusIcon />
                </Button>
              )}
            </>
          )}
        </Box>
        {isLoading ? (
          <Box>
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                paddingBlock={'30px'}
                borderBottom={'1.5px solid #F0EEEE'}
              >
                <Skeleton height="40px" width="85%" borderRadius="md" />
                <Box display="flex" alignItems="center">
                  <Skeleton height="40px" width="40px" borderRadius="md" />
                  <Skeleton
                    height="40px"
                    width="40px"
                    borderRadius="md"
                    ml={2}
                  />
                  <Skeleton
                    height="40px"
                    width="40px"
                    borderRadius="md"
                    ml={2}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          onboardingList.map((onboarding, index) => (
            <Box
              key={index}
              _focus={{ outline: 0 }}
              display="flex"
              justifyContent="space-between"
              paddingBlock={'30px'}
              borderBottom={'1.5px solid #F0EEEE'}
              cursor="pointer"
            >
              <Text
                wordBreak="break-word"
                fontWeight="bold"
                fontSize={22}
                textAlign="start"
              >
                {onboarding.name}
              </Text>
              <Box display={'flex'} alignItems="center">
                {userInfo.companyId === '123' && isPc && (
                  <Box width={'130px'}>
                    {/* <Button
                      disabled={goingDeleteId === newslatterItem.id}
                      title={t('newsletter.edit')}
                      color={'#3FB2D2'}
                      bg={'transparent'}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(
                          `/admin/modify-newslatter/${newslatterItem?.id}`,
                        );
                      }}
                    >
                      <EditIcon2 />
                    </Button> */}
                    <Button
                      // disabled={goingDeleteId === newslatterItem.id}
                      title={t('newsletter.delete')}
                      isDisabled={onboarding.isInUse}
                      color={'#3FB2D2'}
                      bg={'transparent'}
                      onClick={() => {
                        deleteOnboarding(onboarding.id);
                      }}
                    >
                      <TrashIcon />
                    </Button>
                  </Box>
                )}
                {userInfo.companyId === '123' && !isPc && (
                  <Box
                    onClick={(ev) => {
                      ev.stopPropagation();
                    }}
                  >
                    <Menu>
                      <MenuButton bg="transparent" as={Button}>
                        <MenuIcon />
                      </MenuButton>
                      <MenuList overflowY="auto" maxH={'300px'} maxW={'150px'}>
                        <MenuItem
                          display="flex"
                          justifyContent={'space-between'}
                          color="#3FB2D2"
                          isDisabled={onboarding.isInUse}
                          onClick={() => {
                            deleteOnboarding(onboarding.id);
                          }}
                        >
                          {t('newsletter.delete')}
                          <TrashIcon />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                )}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Container>
  );
}

export default OnboardingManagement;
